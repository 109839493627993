import React, { Suspense, lazy } from "react"
import { Switch, Route } from "react-router-dom"

import Loading from "components/Loading"
import NotFound from "views/NotFound"

import Layout from "./Layout"

// routes
const SignIn = lazy(() => import("views/SignIn"))
const Registration = lazy(() => import("views/Registration"))
const Reset = lazy(() => import("views/Reset"))

function AuthRoutes() {
  return (
    <Layout>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/auth/registration" component={Registration} />
          <Route path="/auth/sign-in" component={SignIn} />
          <Route path="/auth/reset-password" component={Reset} />

          {/* Default */}
          <Route path="/auth" component={SignIn} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Layout>
  )
}

export default AuthRoutes
