import { AnyAction } from "redux"
import { SET_AREAS } from "../names"

type Area = {
  id: string
  name: Record<string, string>
  airport: string
  available: boolean
}

export type IAreas = {
  data: Area[]
  limit: number
  offset: number
  total: number
}

export const initAreas = {
  data: [],
  limit: 10,
  offset: 0,
  total: 0,
}

function areas(state: IAreas = initAreas, action: AnyAction) {
  switch (action.type) {
    case SET_AREAS:
      return action.payload

    default:
      return state
  }
}

export default areas
