import { takeLatest, put } from "redux-saga/effects"
import { apiCall } from "./network"
import { GET_REPORTS, GET_REPORTS_AREAS, GET_REPORTS_USERS } from "../names"
import { AnyAction } from "redux"
import {
  setReports,
  setReportsAreas,
  setReportsUsers,
} from "../actions/reports"

const URL = "/api/reports"

function* getReports(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${URL}${action.payload}`

  try {
    const res = yield apiCall(url, "GET", headers)
    const response = yield res.json()
    yield put(setReports(response))
  } catch (error) {
    const x = yield error.json()
    console.error(x)
  }
}

function* getReportsUsers(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${URL}/search-options/users`

  try {
    const res = yield apiCall(url, "GET", headers)
    const response = yield res.json()
    yield put(setReportsUsers(response))
  } catch (error) {
    const x = yield error.json()
    console.error(x)
  }
}

function* getReportsAreas(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `/api/areas/?airport=ATH`

  try {
    const res = yield apiCall(url, "GET", headers)
    const response = yield res.json()
    console.log(response.data)
    yield put(setReportsAreas(response.data))
  } catch (error) {
    const x = yield error.json()
    console.error(x)
  }
}

function* reportsWatcher() {
  yield takeLatest(GET_REPORTS, getReports)
  yield takeLatest(GET_REPORTS_USERS, getReportsUsers)
  yield takeLatest(GET_REPORTS_AREAS, getReportsAreas)
}

export default reportsWatcher
