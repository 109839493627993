import { takeLatest, put } from 'redux-saga/effects';
import { apiCall } from './network';
import * as R from 'ramda';
import {
  GET_CURRENT_REPORTS,
  UPDATE_REPORT,
  REPORT_APPROVE,
  DELETE_REPORT,
  EXPORT_REPORT,
  EXPORT_REPORT_CSV
} from '../names';
import { AnyAction } from 'redux';
import {
  setCurrentReports,
  getCurrentReports as gReport
} from '../actions/reports';
import {
  getCurrentQuestionnaire,
  getCurrentQuestionnaireDropDownValues
} from '../actions/currentQuestionnaire';
import {
  getCurrentRerports as getCReport,
  // setReportResponseError
} from '../actions/currentReport';
import { toast } from 'react-toastify';
import { setErrors } from 'reduxStore/actions/errors';
import { t } from 'I18n';

const URL = '/api/reports/';

function* getCurrentReports(action: AnyAction) {
  const headers = {
    'Content-Type': 'application/json'
  };
  const url = `${URL}${action.payload}`;

  try {
    const reportRes = yield apiCall(url, 'GET', headers);
    const reportResponse = yield reportRes.json();
    const { slug, version, airport } = reportResponse.questionnaire;
    const _url = `${slug}?version=${version}&airport=${airport}&edit=true`;
    const _urlDropDown = `${slug}/dropdown-values?version=${version}&airport=${airport}&edit=true`;
    yield put(getCurrentQuestionnaire(_url));
    yield put(getCurrentQuestionnaireDropDownValues(_urlDropDown));

    yield put(setCurrentReports(reportResponse));
  } catch (error) {
    console.error(error);
  }
}

function* updateReport(action: AnyAction) {
  try {
    const headers = {
      'Content-Type': 'application/json'
    };
    const url = `${URL}${action.payload.id}`;
    yield apiCall(
      url,
      'PATCH',
      headers,
      JSON.stringify(action.payload.sendMe)
    );
    yield put(getCReport(`${action.payload.id}`));
    toast.success(t('int.report-updated-successfully'));
  } catch (error) {
    try {
      const err = yield error.json();
      const path = Object.keys(err.fieldErrors)[0] || '';
      if (path.split('.').length < 3) {
        toast.error(
          t(R.pathOr('int.an-error-has-occurred', ['fieldErrors', path], err))
        );
      }
      yield put(setErrors(err));
    } catch (error) {
      //
    }
  }
}

function* reportApprove(action: AnyAction) {
  try {
    const headers = {
      'Content-Type': 'application/json'
    };
    const { id } = action.payload;
    const url = `${URL}${id}/approve`;
    yield apiCall(url, 'POST', headers);
    yield put(gReport(action.payload.id));

    toast.success('Approved successfully');
  } catch (error) {
    try {
      const { t = (e: any) => e } = action.payload;

      const err = yield error.json();
      yield put(setErrors(err));
      toast.error(t(R.pathOr('int.an-error-has-occurred', ['error'], err)));
    } catch (error) {
      //
    }
  }
}

function* deleteReport(action: AnyAction) {
  try {
    const headers = {
      'Content-Type': 'application/json'
    };
    const url = `${URL}${action.payload}`;
    yield apiCall(url, 'DELETE', headers);
    yield put(gReport(action.payload));
    toast.success('Deleted successfully');
  } catch (error) {
    toast.error(t('int.an-error-has-occurred'));

    // const e = yield error.json()
    console.error(error);
  }
}

function* exportReport(action: AnyAction) {
  try {
    const headers = {
      'Content-Type': 'application/json'
    };
    const { id, lang } = action.payload;
    const url = `${URL}${id}/export?lang=${lang}`;
    const res = yield apiCall(url, 'POST', headers);
    const x = yield res.json();

    // console.log(`/exports/${x.filename}`);
    window.open(
      `/exports/${x.filename}`,
      '_blank',
      'location=yes,height=570,width=520,scrollbars=yes,status=yes'
    );
  } catch (error) {
    // toast.error('int.an-error-has-occurred');

    // const e = yield error.json()
    console.error(error);
  }
}

function* exportReportCsv(action: AnyAction) {
  try {
    const headers = {
      'Content-Type': 'application/json'
    };
    const { id, lang } = action.payload;
    const url = `${URL}${id}/export?type=CSV&lang=${lang}`;
    const res = yield apiCall(url, 'POST', headers);
    const x = yield res.json();

    const a = document.createElement('a');
    a.href = `/exports/${x.filename}`;
    a.download = `${x.filename}`;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove();
  } catch (error) {
    // toast.error('int.an-error-has-occurred');

    // const e = yield error.json()
    console.error(error);
  }
}

function* currentReportWatcher() {
  yield takeLatest(GET_CURRENT_REPORTS, getCurrentReports);
  yield takeLatest(UPDATE_REPORT, updateReport);
  yield takeLatest(REPORT_APPROVE, reportApprove);
  yield takeLatest(DELETE_REPORT, deleteReport);
  yield takeLatest(EXPORT_REPORT, exportReport);
  yield takeLatest(EXPORT_REPORT_CSV, exportReportCsv);
}

export default currentReportWatcher;
