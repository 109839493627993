import React from "react"
import { css } from "emotion"

const styles = {
  root: css`
    label: auth-container;
    display: grid;
    min-height: 100vh;
    grid-template-rows: 64px 1fr;
  `,
  bar: css`
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    background-color: #3f51b5;
    color: #fff;
  `,
  title: css`
    margin: 0;
    color: inherit;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.05px;
  `,
  main: css`
    label: auth-main;
  `,
}

type MinimalProps = {
  children: React.ReactNode
}

const Minimal = (props: MinimalProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.bar}>
        <span className={styles.title}>Aviation Refueling Checklists</span>
      </div>
      <main>{props.children}</main>
    </div>
  )
}

export default Minimal
