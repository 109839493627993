import { createAction } from 'redux-actions';
import {
  GET_OPERATORS,
  SET_OPERATORS,
  DELETE_OPERATOR,
  UPDATE_OPERATOR,
  ADD_NEW_OPERATOR
} from '../names';

export const getOperators = createAction(GET_OPERATORS);
export const setOperators = createAction(SET_OPERATORS);

export const updateOperator = createAction(UPDATE_OPERATOR);
export const deleteOperator = createAction(DELETE_OPERATOR);
export const addNewOperator = createAction(ADD_NEW_OPERATOR);
