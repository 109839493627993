import { takeLatest, put } from "redux-saga/effects"
import { apiCall } from "./network"
import {
  GET_CURRENT_QUESTIONNAIRE,
  GET_CURRENT_QUESTIONNAIRE_DROP_DOWN_VALUES,
  UPDATE_QUESTIONNAIRE,
} from "../names"
import { AnyAction } from "redux"
import {
  setCurrentQuestionnaire,
  setCurrentQuestionnaireDropDownValues,
  // updateQuestionnaire as uQuestionnaire,
  getCurrentQuestionnaire,
} from "../actions/currentQuestionnaire"
import { toast } from "react-toastify"
import { t } from "I18n"

const URL = "/api/questionnaires/"

function* getQuestionnaires(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${URL}${action.payload}`

  try {
    const res = yield apiCall(url, "GET", headers)
    const response = yield res.json()
    yield put(setCurrentQuestionnaire(response))
  } catch (error) {
    console.error(error)
  }
}

function* getQuestionnairesDropDownValues(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${URL}${action.payload}`

  try {
    const res = yield apiCall(url, "GET", headers)
    const response = yield res.json()
    yield put(setCurrentQuestionnaireDropDownValues(response))
  } catch (error) {
    console.error(error)
  }
}

function* updateQuestionnaire(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${URL}`
  delete action.payload.data.dropDownValues
  try {
    const res = yield apiCall(
      url,
      "POST",
      headers,
      JSON.stringify(action.payload.data)
    )
    const response = yield res.json()

    const { slug, version, airportCode } = response

    toast.success(t("int.questionnaire-updated-successfully"))

    yield put(
      getCurrentQuestionnaire(
        `${slug}?version=${version}&airport=${airportCode}`
      )
    )
    action.payload.onSuccess(
      `?version=${version}&airport=${airportCode}&edit=true`
    )
  } catch (error) {
    toast.error(t("int.an-error-has-occurred"))
    const e = yield error.json()
    console.error(e)
  }
}

function* currentQuestionnaireWatcher() {
  yield takeLatest(GET_CURRENT_QUESTIONNAIRE, getQuestionnaires)
  yield takeLatest(UPDATE_QUESTIONNAIRE, updateQuestionnaire)
  yield takeLatest(
    GET_CURRENT_QUESTIONNAIRE_DROP_DOWN_VALUES,
    getQuestionnairesDropDownValues
  )
}

export default currentQuestionnaireWatcher
