import { takeLatest, put } from "redux-saga/effects"
import { apiCall } from "./network"
import {
  GET_VEHICLES,
  UPDATE_VEHICLE,
  ADD_NEW_VEHICLE,
  DELETE_VEHICLE,
} from "../names"
import { AnyAction } from "redux"
import {
  setVehicles,
  // getVehicles as _getVehicles
} from "reduxStore/actions/vehicles"
import { toast } from "react-toastify"
import * as R from "ramda"
import { t } from "I18n"

const URL = "/api/vehicles"

function* getVehicles(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${URL}${action.payload}`

  try {
    const res = yield apiCall(url, "GET", headers)
    const response = yield res.json()
    yield put(setVehicles(response))
  } catch (error) {
    console.error(error)
  }
}

function* updateVehicle(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${URL}/${action.payload.id}`

  try {
    yield apiCall(url, "PUT", headers, JSON.stringify(action.payload.data))
    action.payload.onSuccess()
    toast.success(t("int.vehicle-updated-successfully"))
  } catch (error) {
    const err = yield error.json()
    const errors = Object.keys(err.fieldErrors)
    errors.forEach(key => {
      toast.error(
        `${t(
          R.pathOr("int.an-error-has-occurred", ["fieldErrors", key], err)
        )} ${t(key)}`
      )
    })
  }
}

function* addNewVehicle(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${URL}`

  try {
    yield apiCall(url, "POST", headers, JSON.stringify(action.payload))
    action.payload.onSuccess()
    toast.success(t("int.vehicle-added-successfully"))
  } catch (error) {
    const err = yield error.json()
    const errors = Object.keys(err.fieldErrors)
    errors.forEach(key => {
      toast.error(
        `${t(
          R.pathOr("int.an-error-has-occurred", ["fieldErrors", key], err)
        )} ${t(key)}`
      )
    })
  }
}

function* deleteVehicle(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${URL}/${action.payload.id}`

  try {
    yield apiCall(url, "DELETE", headers)
    action.payload.onSuccess()
    toast.success(t("int.vehicle-deleted-successfully"))
  } catch (error) {
    const err = yield error.json()
    const errors = Object.keys(err.fieldErrors)
    errors.forEach(key => {
      toast.error(
        `${t(
          R.pathOr("int.an-error-has-occurred", ["fieldErrors", key], err)
        )} ${t(key)}`
      )
    })
  }
}
function* vehicleWatcher() {
  yield takeLatest(GET_VEHICLES, getVehicles)
  yield takeLatest(UPDATE_VEHICLE, updateVehicle)
  yield takeLatest(ADD_NEW_VEHICLE, addNewVehicle)
  yield takeLatest(DELETE_VEHICLE, deleteVehicle)
}

export default vehicleWatcher
