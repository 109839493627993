import { AnyAction } from 'redux';
import { SET_VEHICLES } from '../names';

type Vehicle = {
  id: string;
  name: Record<string, string>;
  airport: string;
  available: boolean;
};

export type IVehicles = {
  data: Vehicle[];
  limit: number;
  offset: number;
  total: number;
};

export const initVehicles = {
  data: [],
  limit: 10,
  offset: 0,
  total: 0
};

function vehicles(state: IVehicles = initVehicles, action: AnyAction) {
  switch (action.type) {
    case SET_VEHICLES:
      return action.payload;

    default:
      return state;
  }
}

export default vehicles;
