import React from "react"

export const setBodyAttrs = (lang = "en") => {
  document.body.setAttribute("lang", lang)
  document.body.setAttribute("dir", lang === "ar" ? "rtl" : "ltr")
}

export function usePersistentState(
  key: string,
  defaultValue: string
): [string, React.Dispatch<React.SetStateAction<string>>] {
  const [value, setValue] = React.useState<string>(
    () => localStorage.getItem(key) || defaultValue
  )

  React.useEffect(() => {
    localStorage.setItem(key, value)
  }, [key, value])

  return [value, setValue]
}
