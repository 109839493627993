import { IReduxStore } from "../reducers"
import { put, select } from "redux-saga/effects"
import { logout, login } from "../actions/account"
import { parseJwt } from "../../utils"
import { clearErrors } from "reduxStore/actions/errors"

const ERROR_STATUSES: Array<number> = [400, 401, 403, 404, 415, 500]
const getRefreshToken = (state: IReduxStore) =>
  state.account.refresh_token || ""

export const selectAccessToken = (state: IReduxStore) =>
  state.account.access_token
export const selectAccessTokenExp = (state: IReduxStore) => state.account.exp

export function* getAccessToken() {
  const accessTokenExp = yield select(selectAccessTokenExp)
  const access_token = yield select(selectAccessToken)
  const refreshToken = yield select(getRefreshToken)

  if (accessTokenExp && Number(accessTokenExp) * 1000 < Date.now()) {
    try {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Basic c2FmY28tcmVwb3J0cy1hcHA6czRmYzAtcjNwMHJ0cy00cHA=",
      }

      const res = yield fetch("/api/oauth/token", {
        method: "POST",
        headers,
        body: `grant_type=refresh_token&refresh_token=${refreshToken}`,
      })
      const infos = yield res.json()
      const { exp, user_name, client_id } = parseJwt(infos.access_token)

      yield put(login({ ...infos, exp, user_name, client_id }))
      return infos.access_token
    } catch (e) {
      console.error(e)
      yield put(logout())
    }
  } else {
    return access_token
  }
}

export function* apiCall(
  END_POINT: string,
  METHOD: string,
  HEADERS: Record<string, string>,
  BODY?: BodyInit
) {
  const access_token = yield getAccessToken()

  HEADERS.Authorization = `Bearer ${access_token}`

  yield put(clearErrors())

  const config: RequestInit = {
    method: METHOD,
    headers: HEADERS,
    body: BODY,
  }

  const data: Response = yield fetch(END_POINT, config)

  if ("error" in data || ERROR_STATUSES.includes(data.status)) {
    throw data
  }
  return data
}
