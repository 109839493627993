import { AnyAction } from "redux"
import {
  SET_CURRENT_REPORTS,
  EDIT_REPORT_ANSWER,
  SET_REPORT_RESPONSE_ERROR,
  DELETE_IMAGE,
} from "../names"
import * as R from "ramda"

export type ReponseImage = {
  id: number
  name: string
  path: string
  error?: string
}

export type Response = {
  id: number
  questionId: number
  answerSlug: string
  comment: string
  images: ReponseImage[]
}
export type ReportResponse = Record<string, Response>

export type ICurrentReport = {
  type?: string
  documentNumber?: string
  id?: number
  status?: string
  user?: {
    id: number
    username: string
    firstName: string
    lastName: string
    email: string
  }
  lastModifiedAt?: number
  submittedAt?: number
  approvedBy?: number
  approvedAt?: number
  responses?: ReportResponse
  questionnaire?: {
    slug: string
    version: number
    airport: string
    name: {
      en: string
      el: string
    }
  }
  vehicleId?: number
  area?: string
  airlineId?: number
  registration?: string
  aircraftPositionId?: number
  operatorId?: number
  operatorInformed?: boolean
}

export const initCurrentReport = {}

function currentReport(
  state: ICurrentReport = initCurrentReport,
  action: AnyAction
) {
  switch (action.type) {
    case SET_CURRENT_REPORTS:
      return action.payload
    case EDIT_REPORT_ANSWER:
      return R.assocPath(
        ["responses", `${action.payload.id}`],
        action.payload.values,
        state
      )
    case SET_REPORT_RESPONSE_ERROR:
      return R.assocPath(
        [...action.payload.path, "error"],
        action.payload.value,
        state
      )

    case DELETE_IMAGE:
      const tmpImages = R.path(
        ["responses", action.payload.id, "images"],
        state
      ) as ReponseImage[]
      const idx = tmpImages.findIndex(
        obj => obj.path === action.payload.imagePath
      )
      const images = R.remove(idx, 1, tmpImages)

      return R.assocPath(
        ["responses", action.payload.id, "images"],
        images,
        state
      )

    default:
      return state
  }
}

export default currentReport
