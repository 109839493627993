import { AnyAction } from "redux"
import { SET_AIRLINES } from "../names"

type Airline = {
  id: string
  name: Record<string, string>
  airport: string
}

export type IAirline = {
  data: Airline[]
  limit: number
  offset: number
  total: number
}

export const initAirlines = {
  data: [],
  limit: 10,
  offset: 0,
  total: 0,
}

function airlines(state: IAirline = initAirlines, action: AnyAction) {
  switch (action.type) {
    case SET_AIRLINES:
      return action.payload

    default:
      return state
  }
}

export default airlines
