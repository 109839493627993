import React from "react"
import { useHistory } from "react-router"
import { useSelector, useDispatch } from "react-redux"

import { IReduxStore } from "reduxStore/reducers"
import { getUserInfo } from "reduxStore/actions/users"

type AuthenticatedProps = {
  children: React.ReactNode
}

function Authenticated(props: AuthenticatedProps) {
  const account = useSelector((store: IReduxStore) => store.account)
  const dispatch = useDispatch()
  const history = useHistory()

  const { user_name, userInfo, access_token } = account
  const needToFetchInfo = userInfo === undefined

  React.useEffect(() => {
    if (!access_token) {
      history.push("/auth/sign-in")
    }
    //eslint-disable-next-line
  }, [access_token, history.location.pathname])

  React.useEffect(() => {
    if (user_name && needToFetchInfo) {
      dispatch(getUserInfo())
    }
  }, [user_name, needToFetchInfo, dispatch])

  return <>{props.children}</>
}

export default Authenticated
