import { takeLatest, put } from "redux-saga/effects"
import { apiCall } from "./network"
import { GET_AREAS, UPDATE_AREA, ADD_NEW_AREA, DELETE_AREA } from "../names"
import { AnyAction } from "redux"
import {
  setAreas,
  // getVehicles as _getVehicles
} from "reduxStore/actions/areas"
import { toast } from "react-toastify"
import * as R from "ramda"
import { t } from "I18n"

const URL = "/api/areas"

function* getAreas(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${URL}${action.payload}`

  try {
    const res = yield apiCall(url, "GET", headers)
    const response = yield res.json()
    yield put(setAreas(response))
  } catch (error) {
    console.error(error)
  }
}

function* updateArea(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${URL}/${action.payload.id}`

  try {
    yield apiCall(url, "PUT", headers, JSON.stringify(action.payload.data))
    action.payload.onSuccess()
    toast.success(t("int.area-updated-successfully"))
  } catch (error) {
    const err = yield error.json()
    const errors = Object.keys(err.fieldErrors)
    errors.forEach((key) => {
      toast.error(
        `${t(
          R.pathOr("int.an-error-has-occurred", ["fieldErrors", key], err)
        )} ${t(key)}`
      )
    })
  }
}

function* addNewArea(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${URL}`

  try {
    yield apiCall(url, "POST", headers, JSON.stringify(action.payload))
    action.payload.onSuccess()
    toast.success(t("int.area-added-successfully"))
  } catch (error) {
    const err = yield error.json()
    const errors = Object.keys(err.fieldErrors)
    errors.forEach((key) => {
      toast.error(
        `${t(
          R.pathOr("int.an-error-has-occurred", ["fieldErrors", key], err)
        )} ${t(key)}`
      )
    })
  }
}

function* deleteArea(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${URL}/${action.payload.id}`

  try {
    yield apiCall(url, "DELETE", headers)
    action.payload.onSuccess()
    toast.success(t("int.area-deleted-successfully"))
  } catch (error) {
    const err = yield error.json()
    const errors = Object.keys(err.fieldErrors)
    errors.forEach((key) => {
      toast.error(
        `${t(
          R.pathOr("int.an-error-has-occurred", ["fieldErrors", key], err)
        )} ${t(key)}`
      )
    })
  }
}
function* areaWatcher() {
  yield takeLatest(GET_AREAS, getAreas)
  yield takeLatest(UPDATE_AREA, updateArea)
  yield takeLatest(ADD_NEW_AREA, addNewArea)
  yield takeLatest(DELETE_AREA, deleteArea)
}

export default areaWatcher
