import React, { useState } from "react"
import { cx } from "emotion"
import { makeStyles, useTheme } from "@material-ui/styles"
import { useMediaQuery, Theme } from "@material-ui/core"
import Topbar from "./Topbar"
import Sidebar from "./Sidebar"

const useStyles = makeStyles((theme: any) => ({
  root: {
    paddingTop: 56,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingLeft: 240,
  },
  content: {
    height: "100%",
  },
}))

type MainProps = {
  children: React.ReactNode
}
function Main(props: MainProps) {
  const { children } = props

  const classes = useStyles()
  const theme = useTheme<Theme>()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  })

  const [openSidebar, setOpenSidebar] = useState(false)

  const handleSidebarOpen = () => {
    setOpenSidebar(true)
  }

  const handleSidebarClose = () => {
    setOpenSidebar(false)
  }

  const shouldOpenSidebar = isDesktop ? true : openSidebar

  return (
    <div
      className={cx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        isDesktop={isDesktop}
      />
      <main className={classes.content}>{children}</main>
    </div>
  )
}

export default Main
