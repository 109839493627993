import { createAction } from 'redux-actions';
import {
  GET_VEHICLES,
  SET_VEHICLES,
  DELETE_VEHICLE,
  UPDATE_VEHICLE,
  ADD_NEW_VEHICLE
} from '../names';

export const getVehicles = createAction(GET_VEHICLES);
export const setVehicles = createAction(SET_VEHICLES);

export const updateVehicle = createAction(UPDATE_VEHICLE);
export const deleteVehicle = createAction(DELETE_VEHICLE);
export const addNewVehicle = createAction(ADD_NEW_VEHICLE);
