import decodeToken from "jwt-decode"
export const isTokenExpired = (token?: string): boolean => {
  if (!token) return true
  try {
    const tk: any = decodeToken(token)
    const expiration = +new Date(tk.exp * 1e3)
    return Date.now() > expiration
  } catch (_e) {
    return true
  }
}

export function extractTokenInfo<T>(token: string) {
  const decoded = decodeToken<T>(token)
  return decoded
}
