import store from "reduxStore"
import { logout, login } from "reduxStore/actions/account"
import { refreshTokens } from "api/auth"

import { isTokenExpired, extractTokenInfo } from "./utils"

export type ToauthResponse = {
  access_token: string
  //
  refresh_token: string

  expires_in: number
  jti: string
  scope: string
  token_type: string
}

export type DecodedAccessToken = {
  exp: number
  user_name: string
  jti: string
  client_id: string
  scope: string[]
}

const gtfo = () => store.dispatch(logout())
export const on401 = gtfo
export const on403 = gtfo

function refreshTokensAndReturnAccessToken(refreshToken: string) {
  return refreshTokens(refreshToken)
    .then(tokensResponse => {
      const infos = tokensResponse
      const { exp, user_name, client_id } = extractTokenInfo<
        DecodedAccessToken
      >(infos.access_token)

      store.dispatch(login({ ...infos, exp, user_name, client_id }))
      return infos.access_token
    })
    .catch(errors => {
      console.log("refreshTokensAndReturnAccessToken::errors", errors)
      gtfo()
      return Promise.reject(errors)
    })
}

export async function getAccessToken() {
  const state = store.getState()
  let a = state.account.access_token
  let r = state.account.refresh_token || ""

  const accessToken = isTokenExpired(a) ? "" : a
  const refreshToken = isTokenExpired(r) ? "" : r

  if (!!accessToken) {
    return Promise.resolve(accessToken)
  } else if (!!refreshToken) {
    return refreshTokensAndReturnAccessToken(refreshToken)
  } else {
    gtfo()
    return Promise.reject()
  }
}
