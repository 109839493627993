import { AnyAction } from "redux"
import { SET_REPORTS, SET_REPORTS_USERS, SET_REPORTS_AREAS } from "../names"

export interface IReports {
  data: Record<string, any>[]
  limit: number
  offset: number
  total: number
  loading?: boolean
  users: any
  areas: any
}

export const initReports = {
  data: [],
  limit: 10,
  offset: 0,
  total: 0,
  loading: false,
  users: [],
  areas: [],
}

function reports(state: IReports = initReports, action: AnyAction) {
  switch (action.type) {
    case SET_REPORTS:
      return { ...state, ...action.payload }
    case SET_REPORTS_USERS:
      return { ...state, users: action.payload }

    case SET_REPORTS_AREAS:
      return { ...state, areas: action.payload }

    default:
      return state
  }
}

export default reports
