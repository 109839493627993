import { LoginValues, ToauthResponse } from "types"
import { publik } from "./clients"

export const getTokens = (values: LoginValues): Promise<ToauthResponse> => {
  const searchParams = new URLSearchParams()
  searchParams.set("grant_type", "password")
  searchParams.set("username", values.username)
  searchParams.set("password", values.password)

  return publik
    .post("oauth/token", {
      body: searchParams,
      headers: {
        Authorization: "Basic c2FmY28tcmVwb3J0cy1hcHA6czRmYzAtcjNwMHJ0cy00cHA=",
      },
    })
    .json()
}

export const refreshTokens = (
  refreshToken: string
): Promise<ToauthResponse> => {
  const searchParams = new URLSearchParams()
  searchParams.set("grant_type", "refresh_token")
  searchParams.set("refresh_token", refreshToken)
  return publik
    .post("oauth/token", {
      body: searchParams,
      headers: {
        Authorization: "Basic c2FmY28tcmVwb3J0cy1hcHA6czRmYzAtcjNwMHJ0cy00cHA=",
      },
    })
    .json()
}

// refreshTokens
