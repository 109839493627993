import { AnyAction } from "redux"
import { SET_QUESTIONNAIRES } from "../names"

type Questionnaire = {
  airport: string
  name: { en: string; el: string }
  slug: string
  version: number
}

export type IQuestionnaires = {
  data: Questionnaire[]
  limit: number
  offset: number
  total: number
  loading?: boolean
}

export const initQuestionnaires = {
  data: [],
  limit: 10,
  offset: 0,
  total: 0,
  loading: false,
}

function questionnaires(
  state: IQuestionnaires = initQuestionnaires,
  action: AnyAction
) {
  switch (action.type) {
    case SET_QUESTIONNAIRES:
      return action.payload

    default:
      return state
  }
}

export default questionnaires
