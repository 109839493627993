import React, { useState, useCallback } from "react"
import { cx } from "emotion"

import { useI18nFull } from "I18n"

import { makeStyles } from "@material-ui/styles"
import {
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  MenuItem,
  ListItemIcon,
  Typography,
  Menu,
  Theme,
} from "@material-ui/core"

import MenuIcon from "@material-ui/icons/Menu"
import InputIcon from "@material-ui/icons/Input"

import { useDispatch } from "react-redux"
import { logout } from "reduxStore/actions/account"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  title: {
    color: "#fff",
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}))

type TopbarProps = {
  onSidebarOpen: () => void
  className?: string
}

function Topbar(props: TopbarProps) {
  const { className, onSidebarOpen } = props
  const [anchorLocale, setAnchorLocale] = useState<HTMLButtonElement | null>(
    null
  )
  const { lang, changeLang, t } = useI18nFull()

  const classes = useStyles()

  const dispatch = useDispatch()

  const _logout = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  function handleLocaleOpen(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    setAnchorLocale(event.currentTarget)
  }

  const _changeLang = useCallback(
    locale => {
      changeLang(locale)
      setAnchorLocale(null)
    },
    [changeLang]
  )
  function handleLocaleClose() {
    setAnchorLocale(null)
  }

  return (
    <AppBar className={cx(classes.root, className)}>
      <Toolbar>
        <Typography className={classes.title} variant="h6">
          Aviation Refueling Checklists
        </Typography>
        <div className={classes.flexGrow} />

        <IconButton
          aria-controls="menu-appbar"
          aria-haspopup="true"
          aria-label="account of current user"
          color="inherit"
          onClick={handleLocaleOpen}
          size="small"
          style={{ marginRight: 10 }}
        >
          <img
            alt="language icon"
            src={`/images/${lang}.svg`}
            style={{ height: 28, width: 28 }}
          />
        </IconButton>

        <Menu
          anchorEl={anchorLocale}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          onClose={handleLocaleClose}
          open={Boolean(anchorLocale)}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={() => _changeLang("en")}>
            <ListItemIcon>
              <img
                alt="english language icon"
                src={"/images/en.svg"}
                style={{ height: 25, width: 25 }}
              />
            </ListItemIcon>
            <Typography variant="inherit">{t("int.en")}</Typography>
          </MenuItem>
          <MenuItem onClick={() => _changeLang("el")}>
            <ListItemIcon>
              <img
                alt="greek language icon"
                src={"/images/el.svg"}
                style={{ height: 25, width: 25 }}
              />
            </ListItemIcon>
            <Typography variant="inherit">{t("int.el")}</Typography>
          </MenuItem>
        </Menu>

        <Hidden smDown>
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={_logout}
          >
            <InputIcon />
          </IconButton>
        </Hidden>

        <Hidden mdUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

export default Topbar
