import { select, takeLatest, put } from "redux-saga/effects"
import { apiCall } from "./network"
import {
  GET_USER_INFO,
  GET_USERS,
  GET_CURRENT_USER,
  CREATE_NEW_USER,
  UPDATE_USER,
  INVITE_USER,
  DISABLE_USER,
  ENABLE_USER,
  CHANGE_PASSWORD,
} from "../names"
import { AnyAction } from "redux"
import { IReduxStore } from "../reducers"
import {
  setUserInfo,
  setUsers,
  getCurrentUser as gCurrentUser,
  setCurrentUser,
} from "../actions/users"
import { setErrors } from "reduxStore/actions/errors"
import { t } from "I18n"
import { toast } from "react-toastify"
import * as R from "ramda"

function* getUserInfo(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }

  const user_name = yield select(
    (store: IReduxStore) => store.account.user_name
  )

  try {
    const res = yield apiCall(`/api/users/${user_name}/details`, "GET", headers)
    const response = yield res.json()
    yield put(setUserInfo(response))
  } catch (error) {
    const x = yield error.json()
    console.error(x)
  }
}

const USERS_URL = `/api/users`

function* getUsers(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${USERS_URL}${action.payload}`

  try {
    const res = yield apiCall(url, "GET", headers)
    const response = yield res.json()
    yield put(setUsers(response))
  } catch (error) {
    console.error(error)
  }
}

function* getCurrentUser(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${USERS_URL}/${action.payload}`

  try {
    const res = yield apiCall(url, "GET", headers)
    const response = yield res.json()
    yield put(setCurrentUser(response))
  } catch (error) {
    console.error(error)
  }
}

function* createNewUser(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${USERS_URL}`

  try {
    const res = yield apiCall(
      url,
      "POST",
      headers,
      JSON.stringify(action.payload.infos)
    )
    yield res.json()
    action.payload.push("/users")
    toast.success(t("int.user-created-successfully"))
  } catch (err) {
    const error = yield err.json()
    console.error(error)
    yield put(setErrors(error))
  }
}

function* updateUser(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${USERS_URL}/${action.payload.id}`

  try {
    yield apiCall(url, "PATCH", headers, JSON.stringify(action.payload.infos))

    // yield res.json();
    toast.success(t("int.user-updated-successfully"))
  } catch (err) {
    const error = yield err.json()
    console.error(err)
    yield put(setErrors(error))
  }
}

function* inviteUser(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${USERS_URL}/invite`

  try {
    yield apiCall(url, "POST", headers, JSON.stringify(action.payload))

    // yield res.json();
    toast.success(t("int.user-invited-successfully"))
  } catch (err) {
    const error = yield err.json()
    toast.error(`${t(R.propOr("int.an-error-has-occurred", "error", error))}`)
  }
}

function* disableUser(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${USERS_URL}/disable`

  try {
    yield apiCall(url, "POST", headers, JSON.stringify(action.payload))

    // yield res.json();
    yield put(gCurrentUser(action.payload.id))

    toast.success(t("int.user-disabled-successfully"))
  } catch (err) {
    const error = yield err.json()
    toast.error(`${t(R.propOr("int.an-error-has-occurred", "error", error))}`)
  }
}

function* enableUser(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${USERS_URL}/enable`

  try {
    yield apiCall(url, "POST", headers, JSON.stringify(action.payload))
    // yield res.json();
    yield put(gCurrentUser(action.payload.id))
    toast.success(t("int.user-enable-successfully"))
  } catch (err) {
    const error = yield err.json()
    toast.error(`${t(R.propOr("int.an-error-has-occurred", "error", error))}`)
  }
}

function* changePassword(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${USERS_URL}/change-password`

  try {
    yield apiCall(url, "POST", headers, JSON.stringify(action.payload))
    // yield res.json();
    yield put(gCurrentUser(action.payload.id))
    toast.success(t("int.password-changed-successfully"))
  } catch (err) {
    const error = yield err.json()
    console.error(error)
    if ("fieldErrors" in error) yield put(setErrors(error))
    else
      toast.error(`${t(R.propOr("int.an-error-has-occurred", "error", error))}`)
  }
}

function* usersWatcher() {
  yield takeLatest(GET_USER_INFO, getUserInfo)
  yield takeLatest(GET_USERS, getUsers)
  yield takeLatest(GET_CURRENT_USER, getCurrentUser)
  yield takeLatest(CREATE_NEW_USER, createNewUser)
  yield takeLatest(UPDATE_USER, updateUser)
  yield takeLatest(INVITE_USER, inviteUser)
  yield takeLatest(DISABLE_USER, disableUser)
  yield takeLatest(ENABLE_USER, enableUser)
  yield takeLatest(CHANGE_PASSWORD, changePassword)
}

export default usersWatcher
