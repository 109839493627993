import { AnyAction } from "redux"
import { SET_AIRCRAFTS } from "../names"

type Aircraft = {
  id: string
  name: Record<string, string>
  airport: string
  available: boolean
}

export type IAircrafts = {
  data: Aircraft[]
  limit: number
  offset: number
  total: number
}

export const initAircrafts = {
  data: [],
  limit: 10,
  offset: 0,
  total: 0,
}

function aircrafts(state: IAircrafts = initAircrafts, action: AnyAction) {
  switch (action.type) {
    case SET_AIRCRAFTS:
      return action.payload

    default:
      return state
  }
}

export default aircrafts
