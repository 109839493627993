import * as R from "ramda"
import qs from "query-string"

import { FieldsErrors } from "./types"

export const isResponseJson = (response: Response): boolean =>
  (response.headers.get("Content-Type") || "").indexOf("application/json") !==
  -1

/*
{
  "fieldErrors": {
    "responses.206.comment": "error.report.check.comments.required",
    "responses.206.images": "error.report.check.images.required",
    "operatorId": "error.field.required"
  }
}
*/

export class ExtendableError extends Error {
  constructor(message: string) {
    super(message)
    this.name = this.constructor.name
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = new Error(message).stack
    }
  }
}

export class ApiError extends ExtendableError {
  key: string
  fields: FieldsErrors | null
  constructor(message: string, key?: string, fields?: FieldsErrors) {
    super(message)
    this.name = this.constructor.name
    this.key = key || "server.500"
    this.fields = fields || null
  }

  getMessage(): string {
    return this.message
  }

  getKey(): string {
    return this.key || "server.500"
  }

  getFields(): FieldsErrors | null {
    return this.fields || null
  }

  public static fromResponse(error: any) {
    const message =
      error.message || (error.error_description as string) || "server error"
    const fields = (error.fieldErrors as FieldsErrors) || null
    const key = error.error || error.name || "server.500"

    return new ApiError(message, key, fields)
  }
}

export const parseErrorResponse = async (
  response: Response
): Promise<ApiError> => {
  const J = isResponseJson(response)
  if (J) {
    const jsonError = await response.json()
    const error = jsonError || {}
    return ApiError.fromResponse(error)
  } else {
    let message = await response.text()
    return new ApiError(message)
  }
}

export const responseOKButEmpty = (response: Response): boolean => {
  const noContentType = !response.headers.get("Content-Type")
  const cln = response.headers.get("Content-Length")
  const noContentLenght = cln !== null && +cln === 0
  return response.status === 200 && (noContentType || noContentLenght)
}

export const omitUndefined = (x: Record<string, any>) => R.reject(R.isNil, x)
export const makeParams = (vs: Record<string, any>) =>
  qs.stringify(vs, { arrayFormat: "comma" })

export function makeSearchParams<T>(vs: T) {
  return R.compose(makeParams, omitUndefined)(vs)
}
