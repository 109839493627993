import * as R from "ramda"
import { takeLatest, put } from "redux-saga/effects"
import { apiCall } from "./network"
import {
  GET_OPERATORS,
  UPDATE_OPERATOR,
  ADD_NEW_OPERATOR,
  DELETE_OPERATOR,
} from "../names"
import { AnyAction } from "redux"
import {
  setOperators,
  // getOperators as _getOperators
} from "reduxStore/actions/operators"
import { toast } from "react-toastify"

import { t } from "I18n"

const URL = "/api/operators"

function* getOperators(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${URL}${action.payload}`

  try {
    const res = yield apiCall(url, "GET", headers)
    const response = yield res.json()
    yield put(setOperators(response))
  } catch (error) {
    console.error(error)
  }
}

function* updateOperator(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${URL}/${action.payload.id}`

  try {
    yield apiCall(url, "PUT", headers, JSON.stringify(action.payload.data))
    action.payload.onSuccess()
    toast.success(t("int.operator-updated-successfully"))
  } catch (error) {
    const err = yield error.json()
    const errors = Object.keys(err.fieldErrors)
    errors.forEach(key => {
      toast.error(
        `${t(
          R.pathOr("int.an-error-has-occurred", ["fieldErrors", key], err)
        )} ${t(key)}`
      )
    })
  }
}

function* addNewOperator(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${URL}`

  try {
    yield apiCall(url, "POST", headers, JSON.stringify(action.payload))
    action.payload.onSuccess()
    toast.success(t("int.operator-added-successfully"))
  } catch (error) {
    const err = yield error.json()
    const errors = Object.keys(err.fieldErrors)
    errors.forEach(key => {
      toast.error(
        `${t(
          R.pathOr("int.an-error-has-occurred", ["fieldErrors", key], err)
        )} ${t(key)}`
      )
    })
  }
}

function* deleteOperator(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${URL}/${action.payload.id}`

  try {
    yield apiCall(url, "DELETE", headers)
    action.payload.onSuccess()
    toast.success(t("int.operator-deleted-successfully"))
  } catch (error) {
    const err = yield error.json()
    const errors = Object.keys(err.fieldErrors)
    errors.forEach(key => {
      toast.error(
        `${t(
          R.pathOr("int.an-error-has-occurred", ["fieldErrors", key], err)
        )} ${t(key)}`
      )
    })
  }
}
function* operatorWatcher() {
  yield takeLatest(GET_OPERATORS, getOperators)
  yield takeLatest(UPDATE_OPERATOR, updateOperator)
  yield takeLatest(ADD_NEW_OPERATOR, addNewOperator)
  yield takeLatest(DELETE_OPERATOR, deleteOperator)
}

export default operatorWatcher
