import { takeLatest, put } from "redux-saga/effects"
import { apiCall } from "./network"
import { GET_QUESTIONNAIRES } from "../names"
import { AnyAction } from "redux"
import { setQuestionnaires } from "../actions/questionaires"

const URL = "/api/questionnaires/"

function* getQuestionnaires(action: AnyAction) {
  const headers = {
    "Content-Type": "application/json",
  }
  const url = `${URL}${action.payload}`

  try {
    const res = yield apiCall(url, "GET", headers)
    const response = yield res.json()
    yield put(setQuestionnaires(response))
  } catch (error) {
    console.error(error)
  }
}

function* questionnairesWatcher() {
  yield takeLatest(GET_QUESTIONNAIRES, getQuestionnaires)
}

export default questionnairesWatcher
