import { createAction } from "redux-actions"
import {
  GET_AREAS,
  SET_AREAS,
  DELETE_AREA,
  UPDATE_AREA,
  ADD_NEW_AREA,
} from "../names"

export const getAreas = createAction(GET_AREAS)
export const setAreas = createAction(SET_AREAS)

export const updateArea = createAction(UPDATE_AREA)
export const deleteArea = createAction(DELETE_AREA)
export const addNewArea = createAction(ADD_NEW_AREA)
