import { createAction } from 'redux-actions';
import {
  GET_CURRENT_REPORTS,
  SET_CURRENT_REPORTS,
  UPDATE_REPORT,
  REPORT_APPROVE,
  SET_REPORT_RESPONSE_ERROR,
  DELETE_IMAGE,
  DELETE_REPORT,
  EXPORT_REPORT,
  EXPORT_REPORT_CSV
} from '../names';

export const getCurrentRerports = createAction(GET_CURRENT_REPORTS);
export const setCurrentRerports = createAction(SET_CURRENT_REPORTS);

export const updateReport = createAction(UPDATE_REPORT);
export const reportApprove = createAction(REPORT_APPROVE);
export const setReportResponseError = createAction(SET_REPORT_RESPONSE_ERROR);
export const deleteImage = createAction(DELETE_IMAGE);
export const deleteReport = createAction(DELETE_REPORT);
export const exportReport = createAction(EXPORT_REPORT);
export const exportReportCsv = createAction(EXPORT_REPORT_CSV);
