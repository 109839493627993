import { createAction } from 'redux-actions';
import {
  GET_USER_INFO,
  SET_USER_INFO,
  GET_USERS,
  SET_USERS,
  GET_CURRENT_USER,
  SET_CURRENT_USER,
  CREATE_NEW_USER,
  UPDATE_USER,
  INVITE_USER,
  DISABLE_USER,
  ENABLE_USER,
  CHANGE_PASSWORD
} from '../names';

export const getUserInfo = createAction(GET_USER_INFO);
export const setUserInfo = createAction(SET_USER_INFO);

export const getUsers = createAction(GET_USERS);
export const setUsers = createAction(SET_USERS);

export const getCurrentUser = createAction(GET_CURRENT_USER);
export const setCurrentUser = createAction(SET_CURRENT_USER);
export const createNewUser = createAction(CREATE_NEW_USER);
export const updateUser = createAction(UPDATE_USER);
export const inviteUser = createAction(INVITE_USER);
export const disableUser = createAction(DISABLE_USER);
export const enableUser = createAction(ENABLE_USER);

export const changePassword = createAction(CHANGE_PASSWORD);
