import React from "react"
import Loading from "components/Loading"
import { publik } from "api/clients"

import { setBodyAttrs } from "./utils"
import {
  I18nContextType,
  I18nProviderProps,
  I18nState,
  Translate,
  Messages,
} from "./types"

export let t: Translate = x => x

const I18n = React.createContext<Translate>(t)

export const I18nContext = React.createContext<I18nContextType | undefined>(
  undefined
)

export const availableLangs = ["en", "el"]

const m: Messages = {
  _: {},
}

const getInitialState = () => {
  const lang = localStorage.getItem("_lang") || "en"
  const s: I18nState = {
    lang,
    rtl: lang === "ar",
    direction: lang === "ar" ? "rtl" : "ltr",
    doneAt: 0,
    t,
  }
  return s
}

export function I18nProvider({ children }: I18nProviderProps) {
  const _messages = React.useRef<Messages>(m)
  // const _t = React.useRef<Translate>(x => x)

  const [i18n, setI18n] = React.useState<I18nState>(getInitialState)
  const { lang, doneAt, t: _t } = i18n

  React.useLayoutEffect(() => {
    setBodyAttrs(lang)
  }, [lang])

  React.useEffect(() => {
    const run = async () => {
      try {
        const res = (await publik
          .get(`/translations.json`, {
            prefixUrl: "",
          })
          .json()) as any

        const messages = res as Record<string, Record<string, string>>
        _messages.current = messages

        const new_t: Translate = k =>
          (messages && messages[k] && messages[k][lang]) || k
        t = new_t

        setI18n(s => ({
          ...s,
          doneAt: Date.now(),
          t: new_t,
        }))
      } catch (e) {
        console.log("errorWhileFetchingMessages:", e)
      }
    }
    run()
  }, [lang])

  // React.useEffect(() => {
  //   if (doneAt) {
  //     const messages = _messages.current
  //     _t.current = k => messages[k][lang] || k
  //     t = k => messages[k][lang] || k
  //   }
  // }, [doneAt, lang])

  const changeLang = React.useCallback((lang: string) => {
    const rtl = lang === "ar"
    localStorage.setItem("_lang", lang)

    setI18n(s => ({
      ...s,
      lang,
      rtl,
      direction: "",
    }))
  }, [])

  const contextValue = React.useMemo(() => {
    return { ...i18n, changeLang }
    //eslint-disable-next-line
  }, [changeLang, doneAt])

  return (
    <I18n.Provider value={_t}>
      <I18nContext.Provider value={contextValue}>
        {doneAt ? children : <Loading />}
      </I18nContext.Provider>
    </I18n.Provider>
  )
}

export function useI18nFull() {
  const i18n = React.useContext(I18nContext)
  if (i18n === undefined) {
    throw new Error("useI18n must be used inside I18nProvider")
  }
  return i18n
}

export function useI18n() {
  const t = React.useContext(I18n)
  return t
}

//
export default I18n
