import { AnyAction } from 'redux';
import { SET_OPERATORS } from '../names';

type Operator = {
  id: string;
  name: Record<string, string>;
  airport: string;
  available: boolean;
};

export type IOperators = {
  data: Operator[];
  limit: number;
  offset: number;
  total: number;
};

export const initOperators = {
  data: [],
  limit: 10,
  offset: 0,
  total: 0
};

function operators(state: IOperators = initOperators, action: AnyAction) {
  switch (action.type) {
    case SET_OPERATORS:
      return action.payload;

    default:
      return state;
  }
}

export default operators;
