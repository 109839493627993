import { createAction } from 'redux-actions';
import {
  GET_AIRCRAFTS,
  SET_AIRCRAFTS,
  DELETE_AIRCRAFT,
  UPDATE_AIRCRAFT,
  ADD_NEW_AIRCRAFT
} from '../names';

export const getAircrafts = createAction(GET_AIRCRAFTS);
export const setAircrafts = createAction(SET_AIRCRAFTS);

export const updateAircraft = createAction(UPDATE_AIRCRAFT);
export const deleteAircraft = createAction(DELETE_AIRCRAFT);
export const addNewAircraft = createAction(ADD_NEW_AIRCRAFT);
