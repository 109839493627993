export default {
  gutterBottom: {
    marginBottom: 8,
  },

  // page headers
  h1: {
    fontWeight: 400,
    fontSize: "1.5rem",
    lineHeight: 1.2,
    // letterSpacing: "-0.00833em"
  },
}
