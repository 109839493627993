import { createAction } from 'redux-actions';
import {
  GET_AIRLINES,
  SET_AIRLINES,
  DELETE_AIRLINE,
  UPDATE_AIRLINE,
  ADD_NEW_AIRLINE
} from '../names';

export const getAirlines = createAction(GET_AIRLINES);
export const setAirlines = createAction(SET_AIRLINES);

export const updateAirline = createAction(UPDATE_AIRLINE);
export const deleteAirline = createAction(DELETE_AIRLINE);
export const addNewAirline = createAction(ADD_NEW_AIRLINE);
