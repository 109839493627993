import React, { useCallback, useContext } from "react"
import {
  NavLink as RouterLink,
  LinkProps as RouterLinkProps,
  NavLink,
} from "react-router-dom"
import { useDispatch } from "react-redux"
import { logout } from "reduxStore/actions/account"

import { css, cx } from "emotion"
import { makeStyles } from "@material-ui/styles"
import {
  List,
  ListItem,
  Button,
  colors,
  Hidden,
  Theme,
  ButtonProps,
} from "@material-ui/core"
import InputIcon from "@material-ui/icons/Input"
import I18n from "I18n"
import AuthWrapper from "components/AuthWrapper"

const styles = {
  linksWrapper: css`
    display: flex;
    flex-direction: column;
  `,
  subPage: css`
    border-left: 1px solid #eee;
    display: flex;
    justify-content: bottom;
    padding: 0 8px !important;
    margin-left: 16px;
  `,
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    display: "flex",
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    alignItems: "center",
    textTransform: "none",
    textDecoration: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: (theme.palette as any).icon,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
}))

const AdapterLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (props, ref) => <RouterLink innerRef={ref} {...props} />
)

export function ButtonLink(props: RouterLinkProps & ButtonProps) {
  return <Button component={AdapterLink} {...props} />
}

type Page = {
  title: string
  href: string
  icon: React.ReactNode
  perm: string[]
  subPages?: Page[]
}

type SidebarNavProps = {
  className?: string
  pages: Page[]
}

function SidebarNav(props: SidebarNavProps) {
  const { pages, className } = props
  const t = useContext(I18n)

  const classes = useStyles()
  const dispatch = useDispatch()

  const _logout = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  // activeClassName={classes.active}
  // <ButtonLink className={classes.button} to={page.href}>
  // </ButtonLink>
  return (
    <List className={cx(classes.root, className)}>
      {pages.map((page) => (
        <AuthWrapper key={page.title} reqPerm={page.perm}>
          <ListItem className={classes.item} disableGutters>
            <div className={styles.linksWrapper}>
              <NavLink
                className={classes.button}
                to={page.href}
                activeClassName={classes.active}
              >
                <div className={classes.icon}>{page.icon}</div>
                {page.title}
              </NavLink>

              {page.subPages?.map((subPage, indx) => (
                <div
                  className={css`
                    margin-bottom: 10px;
                  `}
                  key={indx}
                >
                  <AuthWrapper reqPerm={subPage.perm}>
                    <NavLink
                      className={cx(classes.button, styles.subPage)}
                      to={subPage.href}
                      activeClassName={classes.active}
                    >
                      <div className={classes.icon}>{subPage.icon}</div>
                      {subPage.title}
                    </NavLink>
                  </AuthWrapper>
                </div>
              ))}
            </div>
          </ListItem>
        </AuthWrapper>
      ))}
      <Hidden mdUp>
        <ListItem className={classes.item} disableGutters>
          <ButtonLink
            className={classes.button}
            onClick={_logout}
            to="/auth/sign-in"
          >
            <div className={classes.icon}>
              <InputIcon />
            </div>
            {t("int.logout")}
          </ButtonLink>
        </ListItem>
      </Hidden>
    </List>
  )
}

export default SidebarNav
