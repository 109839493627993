import React from "react"
import { ThemeProvider } from "@material-ui/styles"

import { I18nProvider } from "I18n"
import { ReactQueryConfigProvider } from "react-query"

import theme from "./theme"
import Routes from "./Routes"

const queryConfig = {
  refetchAllOnWindowFocus: false,
  retry: 1,
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ReactQueryConfigProvider config={queryConfig}>
        <I18nProvider>
          <Routes />
        </I18nProvider>
      </ReactQueryConfigProvider>
    </ThemeProvider>
  )
}

export default App
