import React, { useContext } from "react"
import { cx } from "emotion"
import { useSelector } from "react-redux"
import { IReduxStore } from "reduxStore/reducers"

import I18n from "I18n"

import { makeStyles } from "@material-ui/styles"

import { Theme } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"

import LibraryBooksIcon from "@material-ui/icons/LibraryBooks"
import AssessmentIcon from "@material-ui/icons/Assessment"
import LocalAirportIcon from "@material-ui/icons/LocalAirport"
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle"
import LocationSearchingIcon from "@material-ui/icons/LocationSearching"

import RoomIcon from "@material-ui/icons/Room"
import PersonIcon from "@material-ui/icons/Person"
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount"
import SettingsIcon from "@material-ui/icons/Settings"
import BarChartIcon from "@material-ui/icons/BarChart"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import Profile from "./Profile"
import SidebarNav from "./SidebarNav"

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("md")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}))

type SidebarProps = {
  onClose: () => void
  open: boolean
  isDesktop: boolean
  className?: string
}

function Sidebar(props: SidebarProps) {
  const { open, isDesktop, onClose, className } = props
  const t = useContext(I18n)
  const classes = useStyles()
  const airports = useSelector(
    (store: IReduxStore) =>
      (store.account.userInfo && store.account.userInfo.airports) || []
  )

  const [firstAirport = "ATH"] = airports.map((e) => e.code || "")

  // 'user:view',
  // 'user:delete',
  // 'user:create',
  // 'user:edit',
  // 'misc:edit',
  // 'questionnaire:edit',
  // 'misc:create',
  // 'report:approve',
  // 'report:edit',
  // 'report:view',
  // 'misc:view',
  // 'airport:view',
  // 'questionnaire:view',
  // 'report:export',
  // 'report:delete',
  // 'misc:delete'

  const pages = [
    {
      title: t("int.reports"),
      href: "/reports",
      icon: <AssessmentIcon />,
      perm: ["report:view", "report:view:own", "report:view:approved"],
    },
    {
      title: t("int.questionnaires"),
      href: "/questionnaires",
      icon: <LibraryBooksIcon />,
      perm: ["questionnaire:view"],
    },
    {
      title: t("int.menu.vehicles"),
      href: `/vehicles?airport=${firstAirport}`,
      icon: <AirportShuttleIcon />,
      perm: ["misc:view"],
    },
    {
      title: t("int.menu.airlines"),
      href: `/airlines?airport=${firstAirport}`,
      icon: <LocalAirportIcon />,
      perm: ["misc:view"],
    },
    {
      title: t("int.menu.areas"),
      href: `/areas?airport=${firstAirport}`,
      icon: <LocationSearchingIcon />,
      perm: ["misc:view"],
    },
    {
      title: t("int.menu.aircraft-positions"),
      href: `/aircraft-position?airport=${firstAirport}`,
      icon: <RoomIcon />,
      perm: ["misc:view"],
    },
    {
      title: t("int.menu.operators"),
      href: `/operators?airport=${firstAirport}`,
      icon: <SupervisorAccountIcon />,
      perm: ["misc:view"],
    },
    {
      title: t("int.users"),
      href: "/users",
      icon: <PersonIcon />,
      perm: ["user:view"],
    },
    {
      title: t("int.analytics"),
      href: "/statistics",
      icon: <BarChartIcon />,
      perm: ["misc:view"],
      subPages: [
        {
          title: t("int.menu.analytics.all"),
          href: "/statistics",
          icon: <></>,
          perm: ["misc:view"],
        },
        {
          title: t("int.menu.analytics.safety-walk-report"),
          href: "/safety-walk-report-stats",
          icon: <></>,
          perm: ["misc:view"],
        },
        {
          title: t("int.menu.analytics.itp-report"),
          href: "/itp-report-stats",
          icon: <></>,
          perm: ["misc:view"],
        },
      ],
    },
    {
      title: t("int.settings"),
      href: "/settings",
      icon: <SettingsIcon />,
      perm: [],
    },
  ]

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={isDesktop ? "persistent" : "temporary"}
    >
      <div className={cx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  )
}

export default Sidebar
