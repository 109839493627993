import { AnyAction } from "redux"
import {
  SET_CURRENT_QUESTIONNAIRE,
  SET_CURRENT_QUESTIONNAIRE_DROP_DOWN_VALUES,
} from "../names"

export type QuestionnaireQuestion = {
  id: number
  no: number
  description: {
    en: string
    el: string
  }
}

export type QuestionnaireAnswer = {
  id: number
  slug: string
  no: number
  abbreviation: {
    en: string
    el: string
  }
  description: {
    en: string
    el: string
  }
}

type DropDownValue = {
  id: number
  name: { en: string; el: string }
}

export interface ICurrentQuestionnaire {
  questions: QuestionnaireQuestion[]
  answers: QuestionnaireAnswer[]
  name: Record<string, string>
  dropDownValues: Record<string, DropDownValue[]>
  slug?: string
  version?: number
  airport?: string
}

export const initCurrentQuestionnaire = {
  questions: [],
  answers: [],
  name: { el: "", en: "" },
  dropDownValues: {},
}

function currentQuestionnaire(
  state: ICurrentQuestionnaire = initCurrentQuestionnaire,
  action: AnyAction
) {
  switch (action.type) {
    case SET_CURRENT_QUESTIONNAIRE:
      return { ...state, ...action.payload }
    case SET_CURRENT_QUESTIONNAIRE_DROP_DOWN_VALUES:
      return { ...state, dropDownValues: action.payload }

    default:
      return state
  }
}

export default currentQuestionnaire
