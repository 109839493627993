import { createAction } from 'redux-actions';
import {
  GET_CURRENT_QUESTIONNAIRE,
  SET_CURRENT_QUESTIONNAIRE,
  GET_CURRENT_QUESTIONNAIRE_DROP_DOWN_VALUES,
  SET_CURRENT_QUESTIONNAIRE_DROP_DOWN_VALUES,
  UPDATE_QUESTIONNAIRE
} from '../names';

export const getCurrentQuestionnaire = createAction(GET_CURRENT_QUESTIONNAIRE);
export const setCurrentQuestionnaire = createAction(SET_CURRENT_QUESTIONNAIRE);
export const updateQuestionnaire = createAction(UPDATE_QUESTIONNAIRE);

export const getCurrentQuestionnaireDropDownValues = createAction(
  GET_CURRENT_QUESTIONNAIRE_DROP_DOWN_VALUES
);
export const setCurrentQuestionnaireDropDownValues = createAction(
  SET_CURRENT_QUESTIONNAIRE_DROP_DOWN_VALUES
);
