import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { useSelector } from "react-redux"
import { IReduxStore } from "reduxStore/reducers"

import { makeStyles } from "@material-ui/styles"
import { Avatar, Typography, Theme } from "@material-ui/core"
import { cx } from "emotion"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}))

type ProfileProps = {
  className?: string
}
function Profile(props: ProfileProps) {
  const { className } = props

  const classes = useStyles()

  const userInfo = useSelector((store: IReduxStore) => store.account.userInfo)

  return (
    <div className={cx(classes.root, className)}>
      <Avatar
        alt="Person"
        to="/settings"
        className={classes.avatar}
        component={RouterLink}
        src={"/images/avatar.png"}
      />
      <Typography className={classes.name} variant="h4">
        {`${userInfo?.firstName ?? ""} ${userInfo?.lastName ?? ""}`}
      </Typography>
      <Typography variant="body2">{userInfo?.email ?? ""}</Typography>
    </div>
  )
}

export default Profile
