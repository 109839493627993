import { AnyAction } from 'redux';
import { SET_USERS, SET_CURRENT_USER } from '../names';
import { Airport } from './account';

export type ICurrentUser = {
  id?: number;
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  roles?: string[];
  permissions?: string[];
  airports?: Airport[];
  status?: string;
};

export interface IUsers {
  data: Record<string, any>[];
  limit: number;
  offset: number;
  total: number;
  loading?: boolean;
  currentUser: ICurrentUser;
}

export const initUsers = {
  data: [],
  limit: 10,
  offset: 0,
  total: 0,
  loading: false,
  currentUser: {}
};

function reports(state: IUsers = initUsers, action: AnyAction) {
  switch (action.type) {
    case SET_USERS:
      return { ...state, ...action.payload };
    case SET_CURRENT_USER:
      return { ...state, currentUser: { ...action.payload } };

    default:
      return state;
  }
}

export default reports;
